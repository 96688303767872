import React from 'react'

// Styles
import {
  VideoSpotlightContainer,
  ProductPageVideoContainer,
  VidWrapper,
  HorizontalVideoSpotlight,
  RenderedVideoSectionContainer,
  NextVideoSectionContainer,
  NextVideo,
} from './style'

// Helpers
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'

// Components
import VideoPlayer from '../videoPlayer'
import GlobalContainer from '../globalContainer'
import Button from '../button'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import {
  internalUrl,
  internalLinkHandler,
  isExternalURL,
} from '../../../utils/link.utils'
import Typography from '../../global/typography'
import videoButtonPlay from '../../../../static/steam-img/video-button-play.svg'
import DefaultGrid from '../../../../static/steam-img/no-image-grid.svg'
import { ModuleNames } from '../../../constants/componentNames.constants'
import { correctSlugPrefix } from '../../../utils/link.utils'
import WrapperImage from '../wrapperImage'

const VideoSpotlight = (props: any) => {
  if (props.data) {
    let theme: string = 'Light',
      headline: string = '',
      subheadline: string = '',
      body: any,
      cta:
        | { externalUrl: string; text: string; internalLink?: internalUrl }
        | undefined,
      logo: string = '',
      logoAltText: string = '',
      videoId: string = '',
      videoBackground: string = '',
      videoBackgroundAltText: string = '',
      topPadding: string = 'Large',
      bottomPadding: string = 'Large',
      useCase: string = '',
      start: string = '',
      animationFileUrl: string = '',
      nextVideo: {}[] = props.data.nextVideo || [],
      nextVideoTitle: string = props.data.nextVideoTitle || ''
    if (props.data.theme) theme = props.data.theme
    if (props.data.headline) headline = props.data.headline
    if (props.data.body) body = props.data.body

    if (props.data.spotlightCta) cta = props.data.spotlightCta
    if (props.data.subheadline && props.data.subheadline.subheadline)
      subheadline = props.data.subheadline.subheadline
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.logo) {
      if (props.data.logo.altText) logoAltText = props.data.logo.altText
      if (
        props.data.logo.image &&
        props.data.logo.image.file &&
        props.data.logo.image.file.url
      )
        logo = props.data.logo.image.file.url
    }
    if (props.data.video) {
      if (props.data.video.youtubeId) videoId = props.data.video.youtubeId
      if (props.data.video.vimeoId) videoId = props.data.video.vimeoId
      if (props.data.video.altText)
        videoBackgroundAltText = props.data.video.altText
      if (
        props.data.video.image &&
        props.data.video.image.file &&
        props.data.video.image.file.url
      )
        videoBackground = props.data.video.image.file.url
      if (props.data.video.start) start = props.data.video.start
      if (props.data.video.animationFile)
        animationFileUrl = props.data.video.animationFile?.file?.url || ''
    }
    if (props.useCase) useCase = props.useCase
    const hasTopSection = logo || headline || subheadline
    const isHorizontal = useCase && useCase === 'horizontal'
    const featuredVideosUseCase = useCase && useCase === 'Featured Videos'

    const renderHeadline = () => {
      if (featuredVideosUseCase) {
        return (
          <Typography className="FeaturedVideos__headline" type="bodyXS">
            {formatInlineCode(headline)}
          </Typography>
        )
      }
      return (
        <h3
          className={`VideoSpotlight__Headline ${
            isHorizontal ? 'VideoSpotlight__Headline-Horizontal' : ''
          }`}
        >
          {formatInlineCode(headline)}
        </h3>
      )
    }

    const renderSubheadline = () => {
      if (isHorizontal) {
        return (
          <Typography className="VideoSpotlight__Subheadline" type="caption">
            {formatInlineCode(subheadline)}
          </Typography>
        )
      } else {
        return (
          <p className="VideoSpotlight__Subheadline">
            {formatInlineCode(subheadline)}
          </p>
        )
      }
    }

    const renderTopSection = () => {
      return (
        <div className="VideoSpotlight__Content-Container">
          {logo && (
            <div className="VideoSpotlight__Logo-Container">
              <WrapperImage
                src={ImgUtil.getResizedImageUrl(logo, 'width=575')}
                loading="lazy"
                alt={logoAltText}
                height={575}
                width={575}
                threshold={650}
              />
            </div>
          )}
          {headline && renderHeadline()}
          {subheadline && renderSubheadline()}
        </div>
      )
    }

    const renderVideoSection = () => {
      return (
        <>
          <VidWrapper>
            <VideoPlayer
              width={'100%'}
              height="429"
              videoId={videoId}
              type={videoId && props.data.video.youtubeId ? 'youtube' : 'vimeo'}
              img={videoBackground}
              backgroundAltText={videoBackgroundAltText}
              backgroundImgParams={'width=732'}
              start={start}
              animationFileUrl={animationFileUrl}
            />
          </VidWrapper>
          {(cta || body) && !isHorizontal && (
            <div className="VideoSpotlight__Content-Container">
              {body && (
                <div className="VideoSpotlight__Body">
                  {renderRichText(body, options)}
                </div>
              )}
              <div className="FeaturedVideo_headline_CTA_container">
                {cta && renderCTA()}
              </div>
            </div>
          )}
        </>
      )
    }
    const renderFeaturedVideoSection = () => {
      return (
        <GlobalContainer className="FeaturedVideos__bottom_container">
          <RenderedVideoSectionContainer>
            <VidWrapper className="FeaturedVideos__MainVideoWrapper">
              <VideoPlayer
                width={'100%'}
                height="429"
                videoId={videoId}
                type={
                  videoId && props.data.video.youtubeId ? 'youtube' : 'vimeo'
                }
                img={videoBackground}
                backgroundAltText={videoBackgroundAltText}
                backgroundImgParams={'width=732'}
                start={start}
                animationFileUrl={animationFileUrl}
              />
              {(cta || headline) && (
                <div className="VideoSpotlight__Content-Container">
                  <div className="FeaturedVideo_headline_CTA_container">
                    {headline && renderHeadline()}
                    {cta && renderCTA()}
                  </div>
                </div>
              )}
            </VidWrapper>
            {nextVideo && nextVideo.length > 0 && (
              <NextVideoSectionContainer>
                {nextVideoTitle && (
                  <Typography
                    className="FeaturedVideos__nextVideoTitle"
                    type="captionAlt"
                  >
                    {nextVideoTitle}
                  </Typography>
                )}
                {nextVideo.map((video: any, index) => {
                  const url = video.slug || ''
                  let image = '',
                    altText = '',
                    teaserText = '',
                    videoIcon = videoButtonPlay
                  switch (video.__typename) {
                    case ModuleNames.PAGE_RESOURCE_DEMO:
                      image = video?.spotlight?.video?.image?.file?.url || ''
                      altText = video?.spotlight?.video?.altText || ''
                      if (
                        video?.teaserBody ||
                        video?.seoMetadata?.pageMetaDescription
                      ) {
                        teaserText =
                          video?.teaserBody ||
                          video?.seoMetadata?.pageMetaDescription
                      }
                      break
                    case ModuleNames.PAGE_PLACEHOLDER:
                      image = video?.mainImage?.image?.file?.url || ''
                      altText = video?.mainImage?.altText || ''
                      if (
                        video?.teaserBody ||
                        video?.seoMetadata?.pageMetaDescription
                      ) {
                        teaserText =
                          video?.teaserBody ||
                          video?.seoMetadata?.pageMetaDescription
                      }
                      break
                    case ModuleNames.PAGE_WEBINAR_RECORDING:
                      image = video?.spotlight?.video?.image?.file?.url || ''
                      altText = video?.spotlight?.video?.altText || ''
                      if (video?.teaserBody || video?.spotlight?.headline) {
                        teaserText =
                          video?.teaserBody ||
                          video?.seoMetadata?.pageMetaDescription
                      }
                      break
                  }
                  const imageOverlay = image ? true : false
                  let linkTarget = '_self'
                  if (isExternalURL(correctSlugPrefix(url))) {
                    linkTarget = '_blank'
                  }

                  return (
                    <NextVideo
                      hasOverlay={imageOverlay}
                      className="FeaturedVideos__Item"
                      key={index}
                    >
                      <a
                        href={correctSlugPrefix(url)}
                        target={linkTarget}
                        className="FeaturedVideos__Item-container-Link"
                      >
                        <div className="FeaturedVideos__Image-Container">
                          <WrapperImage
                            className="FeaturedVideos__Item-Icon"
                            src={videoIcon}
                            alt="Play button"
                            height={56}
                            width={56}
                            threshold={650}
                          />
                          <WrapperImage
                            className="FeaturedVideos__BackgroundImg"
                            src={
                              image
                                ? ImgUtil.getResizedImageUrl(image, 'width=400')
                                : DefaultGrid
                            }
                            height={400}
                            width={400}
                            alt={altText ? altText : 'Dotted Grid'}
                            threshold={650}
                          />
                        </div>
                        <div className="FeaturedVideos__Text-Container">
                          {teaserText && (
                            <Typography
                              className="FeaturedVideos__Item-teaserText"
                              type="bodyXS"
                            >
                              {formatInlineCode(teaserText)}
                            </Typography>
                          )}
                        </div>
                      </a>
                    </NextVideo>
                  )
                })}
              </NextVideoSectionContainer>
            )}
          </RenderedVideoSectionContainer>
        </GlobalContainer>
      )
    }

    const renderCTA = () => {
      return (
        <Button
          icon={true}
          href={
            cta?.internalLink
              ? internalLinkHandler(cta?.internalLink)
              : cta?.externalUrl
          }
          text={cta.text}
          type={featuredVideosUseCase ? 'tertiary' : 'text-link'}
          size="small"
          theme={featuredVideosUseCase ? 'dark' : theme.toLowerCase()}
        />
      )
    }

    const renderHorizontalSpotlight = () => {
      return (
        <GlobalContainer>
          <HorizontalVideoSpotlight data-cy="VideoSpotlight-Horizontal">
            {(headline || subheadline || cta) && (
              <div className="VideoSpotlight__Horizontal-Text">
                {subheadline && renderSubheadline()}
                {headline && renderHeadline()}
                {body && (
                  <div className="VideoSpotlight__Body">
                    {renderRichText(body, options)}
                  </div>
                )}
                {cta && renderCTA()}
              </div>
            )}
            {(videoId || animationFileUrl) && renderVideoSection()}
            <div className="VideoSpotlight__Robot-Illustration">
              <WrapperImage
                alt="UiPath Robot Illustration"
                src={ImgUtil.getResizedImageUrl(
                  'https://images.ctfassets.net/5965pury2lcm/3fTUgGix12QmaA6D2qhYyu/e4fda7d18f72d2f6051ab9f9c39e19de/developers-uipath-robot-transparent.png',
                  'width=300'
                )}
                width={300}
                height={300}
                threshold={650}
              />
            </div>
          </HorizontalVideoSpotlight>
        </GlobalContainer>
      )
    }

    if (isHorizontal) {
      return renderHorizontalSpotlight()
    } else {
      return (
        <VideoSpotlightContainer
          background={useCase && useCase === 'Resource' ? 'Light' : theme}
          topPadding={useCase && useCase === 'Resource' ? 'None' : topPadding}
          useCase={useCase}
          featuredVideosUseCase={featuredVideosUseCase}
          data-cy="VideoSpotlight"
        >
          {hasTopSection && !featuredVideosUseCase && renderTopSection()}
          <ProductPageVideoContainer
            bottomPadding={bottomPadding}
            useCase={useCase}
            featuredVideosUseCase={featuredVideosUseCase}
          >
            {(videoId || animationFileUrl) &&
              (featuredVideosUseCase
                ? renderFeaturedVideoSection()
                : renderVideoSection())}
          </ProductPageVideoContainer>
        </VideoSpotlightContainer>
      )
    }
  }
  return null
}
export default VideoSpotlight
