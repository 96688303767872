import styled from 'styled-components'
export const ProductPageVideoContainer = styled.div`
  ${(p) => {
    let paddingTop = ``
    if (p.bottomPadding === 'Large') paddingTop = `padding-bottom: 96px;`
    if (p.bottomPadding === 'Small') paddingTop = `padding-bottom: 64px;`

    const dotGridLgHeight = '263px'
    const dotGridMdHeight = '220px'
    const dotGridSmHeight = '197px'
    const dotGridXsHeight = '104px'

    const spotlightLineSplit = `
    &:before {
        content: '';
        background-size: 22px 22px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: ${dotGridLgHeight};
        width: 1200px;
        z-index: 1;
        ${p.theme.breakpoints.down('md')} {
          height: ${dotGridMdHeight};
          width: 960px;
        }
        ${p.theme.breakpoints.down('sm')} {
          height: ${dotGridSmHeight};
          width: 736px;
        }
        ${p.theme.breakpoints.down('xs')} {
          height: ${dotGridXsHeight};
          background-image: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: calc(100% - ${dotGridLgHeight});
        ${p.theme.breakpoints.down('md')} {
          height: calc(100% - ${dotGridMdHeight});
        }
        ${p.theme.breakpoints.down('sm')} {
          height: calc(100% - ${dotGridSmHeight});
        }
        ${p.theme.breakpoints.down('xs')} {
          height: calc(100% - ${dotGridXsHeight});
        }
      }
    `
    const featuredVideoStyle = `
      background-color: ${(p) => p.theme.webMktPalette.background.dark};
    `

    return `
      width: 100%;
      text-align: center;
      background-color: ${
        p.featuredVideosUseCase ? p.theme.webMktPalette.background.dark : '#fff'
      };
      position: relative;
      overflow: hidden;
      ${paddingTop}
      ${!p.featuredVideosUseCase ? spotlightLineSplit : ''}
      ${featuredVideoStyle}
      .VideoSpotlight__Body {
        position: relative;
        z-index: 1;
        margin-top: 32px;
        p {
          font-size: 20px;
          font-weight: normal;
          letter-spacing: -0.5px;
          line-height: 32px;
          ${p.theme.breakpoints.down('xs')} {
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 24px;
          }
        }
      }

      .CTA {
        margin-top: 32px;
        z-index: 1;
      }

     
    `
  }}

  .FeaturedVideos__bottom_container {
    ${(props) => props.theme.breakpoints.down('xs')} {
      padding: 0;
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-bottom: 48px;
  }
`

export const VidWrapper = styled.div`
  margin: 0 auto;
  padding-top: 48px;
  max-width: 792px;

  .FeaturedVideo_headline_CTA_container {
    display: flex;
    margin-top: 24px;
    .FeaturedVideos__headline {
      text-align: left;
      max-width: 450px;
      color: ${(p) => p.theme.webMktPalette.text.primaryWhite};
    }

    .CTA {
      margin-top: 0px;
      margin-left: auto;
      ${(props) => props.theme.breakpoints.down('xs')} {
        margin-top: 32px;
      }
    }

    ${(props) => props.theme.breakpoints.down('xs')} {
      display: flex;
      flex-direction: column;

      .CTA {
        width: 250px;
        margin-left: 0;
      }
    }
  }

  img {
    width: 100%;
    height: 429px;
    object-fit: cover;
    object-position: center;

    ${(p) => p.theme.breakpoints.down('md')} {
      height: 343px !important;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
      height: 297px !important;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      height: 191px !important;
    }
  }
  ${(p) => p.theme.breakpoints.down('md')} {
    max-width: 632px;
  }
  ${(p) => p.theme.breakpoints.down('sm')} {
    max-width: 548px;
  }
  ${(p) => p.theme.breakpoints.down('xs')} {
    padding: 0 16px;
  }
`

export const HorizontalVideoSpotlight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 104px;
  ${(p) => p.theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;
    padding-bottom: 48px;
  }
  .VideoSpotlight__Headline-Horizontal {
    margin: 0 0 16px 0;
  }
  .VideoSpotlight__Horizontal-Text {
    width: 282px;
    ${(p) => p.theme.breakpoints.down('md')} {
      width: 222px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 253px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 100%;
      margin-top: 24px;
    }
  }
  ${VidWrapper} {
    position: relative;
    width: 564px;
    height: 282px;
    margin: 0 auto 0 24px;
    ${(p) => p.theme.breakpoints.down('md')} {
      width: 462px;
      height: 190px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 429px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 100%;
      max-width: 100%;
      margin: 48px 0 0 0;
    }
    &:before {
      content: '';
      background-image: radial-gradient(rgba(0, 0, 0, 0.7) 1px, transparent 0);
      position: absolute;
      width: 564px;
      height: 282px;
      z-index: 0;
      bottom: -56px;
      right: -125px;
      background-size: 24px 24px;
      ${(p) => p.theme.breakpoints.down('md')} {
        width: 483px;
        height: 202px;
        right: -96px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        width: 441px;
        right: -58px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        display: none;
      }
    }

    .VideoPlayer__Image-File {
      height: 282px;
      ${(p) => p.theme.breakpoints.down('md')} {
        max-height: 190px;
      }
    }
  }
  .VideoSpotlight__Robot-Illustration {
    width: 179px;
    ${(p) => p.theme.breakpoints.down('md')} {
      width: 140px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`

export const VideoSpotlightContainer = styled.div`
  ${(p) => {
    if (p.topPadding === 'Large') return `padding-top: 96px;`
    if (p.topPadding === 'Small') return `padding-top: 64px;`
    if (p.topPadding === 'None') return `padding-top: 0;`
    if (p.featuredVideosUseCase) {
      return `
        background-color: ${(p) => p.theme.webMktPalette.background.dark};
      `
    }
  }}

  ${(p) => {
    if (p.featuredVideosUseCase) {
      return `
        padding-top: 0;
        padding-bottom: 48px;
      `
    }
  }}
  

  .VideoSpotlight__Logo-Container {
    max-width: 575px;
    width: 100%;
    margin: 0 auto 24px auto;
    img {
      width: 100%;
      height: auto;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      padding: 0 21px;
      box-sizing: border-box;
    }
  }

  .VideoSpotlight__Content-Container {
    max-width: 792px;
    margin: 0 auto;
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 736px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .VideoSpotlight__Headline {
    text-align: center;
    margin-bottom: 24px;
    color: rgb(0, 0, 0);
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 28px;
      letter-spacing: 0px;
      line-height: 36px;
    }
  }
  .VideoSpotlight__Subheadline {
    color: ${(p) => p.theme.webMktPalette.text.primaryBlack};
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 36px;
    margin-bottom: 64px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 48px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 20px;
      letter-spacing: 0px;
      line-height: 32px;
    }
  }

  ${(props) => {
    let themeStyles = ``
    if (props.background === 'Dark') {
      themeStyles += `
        ${ProductPageVideoContainer}:before {
          background-color: white;
        }
        ${ProductPageVideoContainer}:after {
          background-color: ${props.theme.webMktPalette.background.dark};
        }
        .VideoSpotlight__Body {
          p {
            color: ${props.theme.webMktPalette.background.white};
          }
        }
      `
    } else {
      //Light or Grey
      if (props.background === 'Light') {
        themeStyles += `
          background-color: ${props.theme.webMktPalette.background.gray};
          ${ProductPageVideoContainer} {
            background-color: ${props.theme.webMktPalette.background.gray};
            &:before {
              background-color: ${props.theme.webMktPalette.background.gray};
            }
            &:after {
              background-color: ${
                props.useCase && props.useCase === 'Resource'
                  ? props.theme.webMktPalette.background.gray
                  : props.theme.webMktPalette.background.white
              };
            }
          }
        `
      } else {
        themeStyles += `
          ${ProductPageVideoContainer}:before {
            background-color: white;
          }
          ${ProductPageVideoContainer}:after {
            background-color: ${props.theme.webMktPalette.background.gray};
          }
        `
      }
      themeStyles += `
        .VideoSpotlight__Body {
          p {
            color: ${props.theme.webMktPalette.text.primaryBlack};
          }
        }
      `
    }

    const dottedBackground = `
    ${ProductPageVideoContainer} {
      &:before {
        background-image: radial-gradient(${
          props.useCase && props.useCase === 'Resource' ? '#000000' : `#cecece`
        } 1px, transparent 0);
      }
    }
    `

    return `
      ${themeStyles}
      ${!props.featuredVideosUseCase ? dottedBackground : ''}
    `
  }};
`

// useCase === 'Featured Videos' | featuredVideosUseCase
export const RenderedVideoSectionContainer = styled.div`
  display: flex;
  .FeaturedVideos__MainVideoWrapper {
    margin: 0 0;
    max-width: 895px;
    padding-top: 0px;
    ${(props) => props.theme.breakpoints.down('xs')} {
      padding: 0;
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    display: block;
  }

  .FeaturedVideos__Item .FeaturedVideos__Item-container-Link {
    ${(p) => p.theme.breakpoints.down('xs')} {
      display: flex;
    }
  }
`

export const NextVideoSectionContainer = styled.div`
  max-width: 282px;
  margin-left: 24px;

  .FeaturedVideos__nextVideoTitle {
    color: ${(p) => p.theme.webMktPalette.text.primaryWhite};
    text-align: left;
    margin-bottom: 24px;
    margin-top: 0px;
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: 16px;
    max-width: 100%;
  }
`

export const NextVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  ${(p) =>
    p.hasOverlay
      ? `
    .FeaturedVideos__Image-Container:before {
      content: '';
      background: rgb(0, 0, 0, .1);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }`
      : ''}

  .FeaturedVideos__Image-Container {
    width: 100%;
    min-height: 147px;
    position: relative;

    ${(p) => p.theme.breakpoints.down('xs')} {
      height: 70px;
      width: 127px;
      min-height: 70px;
      min-width: 127px;
    }

    img {
      object-fit: cover;
      object-position: center;
    }

    .FeaturedVideos__BackgroundImg {
      width: 100%;
      height: 100%;
      min-height: 147px;
      ${(p) => p.theme.breakpoints.down('xs')} {
        min-height: 70px;
      }
    }
  }

  .FeaturedVideos__Item-Icon {
    position: absolute;
    opacity: 0.8;
    right: 1px;
    bottom: 1px;
    height: 48px;
    width: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .FeaturedVideos__Item-teaserText {
    text-align: left;
    color: ${(p) => p.theme.webMktPalette.text.primaryWhite};
    margin-top: 16px;

    ${(p) => p.theme.breakpoints.down('xs')} {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 0;
      max-width: 100%;
    }
  }
`
